<template>
	<div class="bg-F7F7F7 paddt20">
		<div class="swiper-box-index">
			<!-- 轮播 -->
			<div class="swiper-index">
				<swiper :slidesPerView="1" :spaceBetween="30" :loop="true" :autoplay="true" :pagination="pagination" :navigation="navigation" class="mySwiper">
					<swiper-slide v-for="(item,index) in pagesinfo.carousel" :key='index' @click="godetails(item)">
						<el-image
						     class="swiper-pic display-block w100 "
						      :src="item.pc_image"
						      fit="fit"></el-image>
					</swiper-slide>
					
					<div class="swiper-button-prev"></div>
					<div class="swiper-button-next"></div>
				</swiper>
			</div>
		</div>
		
		<div class="center flex-column w100">
			<div class="bg-white w100">
				<div class="w1200 margin-center">
					<div class="justify-content-center-between h80">
						<div class="flex-1 h100 center cure-pointer" @click="toClassType(1)">
							<img class="co_01 display-block" src="@/assets/img/co_01.png" alt="" />
							<span class="fz20 co-222222 display-block marl16">公开课</span>
						</div>
						<div class="middle"></div>
						<div class="flex-1 h100 center cure-pointer" @click="toClassType(2)">
							<img class="co_01 display-block" src="@/assets/img/co_02.png" alt="" />
							<span class="fz20 co-222222 display-block marl16">软件课</span>
						</div>
						<div class="middle"></div>
						<div class="flex-1 h100 center cure-pointer" @click="toClassType(3)">
							<img class="co_01 display-block" src="@/assets/img/co_03.png" alt="" />
							<span class="fz20 co-222222 display-block marl16">设计课</span>
						</div>
					</div>
				</div>
			</div>
			
			<div class="h60 fz16 co-333333 font-blod justify-content-center-between w1200 margin-center cure-pointer" @click="toClassType(4)">
				<span>推荐课程</span>
				<img class="arrow_right_blank" src="../assets/img/arrow_right_blank.png" alt="" />
			</div>
			<div class="center w100">
				<div class="w1200">
					<ClassSelect :list="pagesinfo.recommend"></ClassSelect>
				</div>
			</div>
			<div class="paddb19 fz16 co-333333 font-blod justify-content-center-between w1200 margin-center cure-pointer" @click="toClassType(5)">
				<span>免费课程</span>
				<img class="arrow_right_blank" src="../assets/img/arrow_right_blank.png" alt="" />
			</div>
			<div class="center w100">
				<div class="w1200">
					<ClassSelect :list="pagesinfo.free"></ClassSelect>
				</div>
			</div>
			<div v-loading.fullscreen.lock="fullscreenLoading"></div>
			<!-- <div class="center paddt54 paddb10">
				<el-pagination background  class="center "  @current-change="handleCurrentChange"
				    layout="prev, pager, next, jumper" :total="classroomlist.length" :page-size="size">
				</el-pagination>
			</div> -->
		</div>
	</div>
</template>

<script>
	// Import Swiper Vue.js components
	import { Swiper, SwiperSlide } from 'swiper/vue';
	
	// Import Swiper styles
	import 'swiper/swiper.less';
	
	import "swiper/components/pagination/pagination.min.css"
	import "swiper/components/navigation/navigation.min.css"
	
	import SwiperCore, { Pagination,Navigation, Autoplay } from 'swiper/core';
	
	SwiperCore.use([Pagination, Navigation, Autoplay]);
	
	export default {
		name: "School",
		components: {
			Swiper,
			SwiperSlide,
		},
		data() {
			return {
				state: 1, // 列表加载状态
				page: 0, // 分页
				loading: false, // 接口加载状态
				
				list: [], // 列表
				pagesinfo:{ //页面内容
					
				},
				size: 16,
				pagination: {
					"clickable": true,
					"bulletActiveClass": "box-01-active",
					"type": 'bullets'
				},
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				},
				classroomlist: [],
				fullscreenLoading: true,
			}
		},
		mounted() {
			this.$req({ 
			method: 'get',
			url: '/api/course/index',
			success: res => {	
				this.pagesinfo = res;
				this.fullscreenLoading =false;
				console.log(this.pagesinfo)
			},
			fail: error => {}
			});
		},
		methods: {
			// 课程类型跳转
			toClassType(e) {
				this.$router.push({  //核心语句
					path:'/GoodClass',   //跳转的路径
					query: { type: e }
				})
			},
		godetails(item) {
			console.log(item)
			// 1素材市场 2研图社 3竞赛信息 4院校指南 5项目灵感
			let data = item.link_type;
			let id = item.link_id
			let type = '';
			let url = '/Detail';
			if (data == 'source') {
				type = 1
			} else if (data == 'picture') {
				type = 2
			} else if (data == 'competition') {
				type = 3
			} else if (data == 'school') {
				type = 4
			} else if (data == 'inspiration') {
				type = 5
			}
			else if (data == 'teachar') {
				this.$router.push({
					path: '/TeacherDetail',
					query: {
						type: type,
						id: id
					}
				})
				return
			}
			else if (data == 'agency') {
				this.$router.push({
					path: '/SincerityDetail',
					query: {
						type: type,
						id: id
					}
				})
				return
			}
			else if (data == 'course') {
				this.$router.push({
					path: '/ClassDetail',
					query: {
						type: type,
						id: id
					}
				})
				return
			}
			
			this.$router.push({
				path: url,
				query: {
					type: type,
					id: id
				}
			})
			
		},
			// 假分页
			// paging() {
			//     let all = this.classroomlist;
			//     let size = this.size;
			//     let index = (this.page - 1) * size;
			//     let arr = [];
			//     for (let i = 0; i < size; i++) {
			//         if (index < all.length) {
			//             arr.push(all[index])
			//         }
			//         index++;
			//     }
			//     this.list = arr;
			// },
			handleCurrentChange(val) {
			    this.page = val;
			    // this.paging();
			    document.documentElement.scrollTop = document.body.scrollTop = 400;
			},
		}
	}
</script>

<style lang="less">
	// 轮播
	.swiper-box-index {
		height: 500px;
		
		.swiper-index {
			height: 500px;
		}
		
		.swiper-slide {
			text-align: center;
			font-size: 18px;
			background: #fff;
		
			/* Center slide text vertically */
			display: -webkit-box;
			display: -ms-flexbox;
			display: -webkit-flex;
			display: flex;
			-webkit-box-pack: center;
			-ms-flex-pack: center;
			-webkit-justify-content: center;
			justify-content: center;
			-webkit-box-align: center;
			-ms-flex-align: center;
			-webkit-align-items: center;
			align-items: center;
		}
		
		.swiper-slide img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
		
		.swiper-button-next, .swiper-button-prev {
			position: absolute;
			top: 50%;
			width: calc(var(--swiper-navigation-size)/ 44 * 27);
			height: var(--swiper-navigation-size);
			margin-top: calc(0px - (var(--swiper-navigation-size)/ 2));
			z-index: 10;
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;
			color: var(--swiper-navigation-color,var(--swiper-theme-color));
		}
		
		.swiper-button-prev {
			width: 36px;
			height: 36px;
			background: url(../assets/img/prev.png) no-repeat;
			background-position: 0 0;
			background-size: 100%;
			left: 10px;
			right: auto;
		}
		
		.swiper-button-next {
			width: 36px;
			height: 36px;
			background: url(../assets/img/next.png) no-repeat;
			background-position: 0 0;
			background-size: 100%;
			right: 10px;
			left: auto;
		}
		
		.swiper-container {
			width: 100%;
			height: 100%;
		}
		
		.swiper-container {
			margin-left: auto;
			margin-right: auto;
		}
		
		.img-box {
			background: rgba(0, 0, 0, 0.5);
			transition: all .3s;
			display: none;
		}
		
		.swiper-button-prev::after {
			content: '';
		}
		
		.swiper-button-next::after {
			content: '';
		}
		
		.box-01-active {
			background: #FFFFFF !important;
			opacity: 1 !important;
		}
		
		.swiper-pagination {
			position: absolute;
			text-align: center;
			transition: .3s opacity;
			transform: translate3d(0,0,0);
			z-index: 10;
		}
		
		.swiper-pagination-bullet {
			width: 8px;
			height: 8px;
			display: inline-block;
			border-radius: 50%;
			background: #000;
			opacity: .2;
			margin: 0 4px;
		}
		
		.swiper-pagination-bullet:hover {
			cursor: pointer;
		}
		
		.swiper-pagination-bullets {
			bottom: 10px;
			left: 0;
			width: 100%;
		}
	}
	
	.img-box {
		background: rgba(0, 0, 0, 0.5);
		transition: all .3s;
		display: none;
	}
	
	.swiper-button-prev::after {
		content: '';
	}
	
	.swiper-button-next::after {
		content: '';
	}
	
	.box-01-active {
		background: #FFFFFF !important;
		opacity: 1 !important;
	}
	
	.co_01 {
		width: 44px;
		height: 44px;
	}
	
	.middle {
		width: 1px;
		height: 24px;
		background: #CCCCCC;
	}
	
	.arrow_right_blank {
		width: 14px;
		height: 14px;
	}
	
	/* 排序 */
	.sort {
		width: 90rpx;
		height: 28px;
		border: 1px solid #010085;
		border-radius: 3px;
		padding: 0 8px;
		
		.pull-pic {
			width: 14px;
			height: 14px;
			transition: all 0.3s;
		}
		
		.rotate {
			transform: rotate(180deg);
		}
	}
	
	// 动画过渡
	.animation {
		transition: all 0.3s;
	}
	
	// 改变组件样式
	.el-pager li {
		font-size: 14px !important;
		width: 40px !important;
		height: 40px !important;
		border-radius: 4px !important;
		border: 1px solid #CCCCCC !important;
		line-height: 40px !important;
		padding: 0 !important;
		color: #999999!important;
	}
	
	.btn-prev, .btn-next {
		width: 40px !important;
		height: 40px !important;
		line-height: 40px !important;
		border-radius: 4px !important;
		border: 1px solid #CCCCCC !important;
	}
	
	.el-pager .active {
		background: #DCFF03 !important;
		color: #010085 !important;
		border: 1px solid #DCFF03 !important;
	}
</style>
